import React from 'react'
import styled from 'styled-components'

import { Wrapper, Image } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { AWARD_LOGO_URL } from 'lib/awards/constants'

const LogosWrapper = styled(Wrapper)`
  & > *:not(:first-child) {
    margin-left: ${GTR.XS};
  }

  & img {
    height: 48px;
  }
`

const LOGOS = [AWARD_LOGO_URL.GOOD_FUNERAL_AWARDS, AWARD_LOGO_URL.TRUSTPILOT]

const ExternalLogos = () => (
  <LogosWrapper margin={['L', 0]}>
    {LOGOS.map((path) => (
      <Image key={path} path={path} width={98} />
    ))}
  </LogosWrapper>
)

export default ExternalLogos
