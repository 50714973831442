import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Wrapper } from '@farewill/ui'

import { COLOR } from '@farewill/ui/tokens'
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'

import { PRODUCTS } from 'lib/products/constants'

const SliceFAQ = ({
  phoneNumber,
}: {
  phoneNumber: GatsbyTypes.ContentfulLocalFuneralPage['phoneNumber']
}): React.ReactElement => {
  const { faqSection } = useStaticQuery<{
    faqSection: GatsbyTypes.ContentfulFaqSection
  }>(graphql`
    query {
      faqSection: contentfulFaqSection(entryTitle: { eq: "Direct cremation" }) {
        ...FaqSectionFields
      }
      guide: contentfulGuide(slug: { eq: "how-to-arrange-a-cremation" }) {
        slug
      }
    }
  `)

  /* Usually the title comes from the article on Contentful, however we don't want to rename
  the file on Contentful as it's being used in other places. 
  */
  const frequentlyAskedQuestionsTitle = 'Frequently asked questions'

  return (
    <Wrapper background={COLOR.WHITE}>
      <Wrapper container containerPaddingBottom={0}>
        <FrequentlyAskedQuestions
          title={frequentlyAskedQuestionsTitle}
          questions={faqSection.questions}
          relatedGuide={faqSection.relatedGuide}
          selectedProduct={PRODUCTS.FUNERAL}
          customPhoneNumber={phoneNumber}
          preventSwapping
        />
      </Wrapper>
    </Wrapper>
  )
}

export default SliceFAQ
