import React, { ReactElement, useRef } from 'react'
import { Grid, H, Image, P, Wrapper } from '@farewill/ui'
import {
  COLOR,
  BORDER,
  BORDER_RADIUS,
  FONT,
  BREAKPOINT,
} from '@farewill/ui/tokens'
import styled, { css } from 'styled-components'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { useIntersection } from 'react-use'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import BreakpointSwitch from 'components/BreakpointSwitch'
import ExternalLink from 'components/ExternalLink'
import { Provider } from './data'

const StyledWrapper = styled(Wrapper)<{ $largeBorderRadius?: boolean }>`
  border-radius: ${({ $largeBorderRadius }) =>
    $largeBorderRadius ? '16px' : BORDER_RADIUS.S};
  box-shadow: ${BORDER.SHADOW.M};
  z-index: 1;
  position: relative;
`

const StyledPrice = styled.div`
  font-family: ${FONT.FAMILY.DECORATIVE};
  font-size: ${FONT.SIZE.L};
  color: ${COLOR.BLACK};
`

const StyledBar = styled(Wrapper)<{
  $animated?: boolean
  $isVisible?: boolean
  $width: number
  $widthFromS: number
}>`
  border-radius: ${BORDER_RADIUS.S};
  display: flex;
  justify-content: space-between;

  ${({ $width, $widthFromS, $isVisible, $animated }) => css`
    ${$animated && `transition: max-width 1.3s cubic-bezier(.47,1.64,.41,.8);`}

    ${$animated
      ? `max-width: ${$isVisible ? $width : 35}%;`
      : `max-width: ${$width}%;`}

    ${screenMin.m`
      ${
        $animated
          ? `max-width: ${$isVisible ? $widthFromS : 35}%;`
          : `max-width: ${$widthFromS}%;`
      }
    `};
  `}

  ${StyledPrice} {
    opacity: 0;
    transition: opacity 1s ease;

    ${({ $isVisible, $animated }) => css`
      ${$animated ? `opacity: ${$isVisible ? 1 : 0};` : `opacity: 1;`}
    `}

    ${screenMax.m`
      transition-delay: 0.4s;
    `}
  }
`

const StyledImage = styled(Image)`
  max-width: 190px;
  object-fit: contain;
`

const DesktopImage = ({ provider }: { provider: Provider }) => (
  <StyledImage
    path={provider.logotype}
    width={300}
    height={36}
    alt={provider.name}
  />
)

const MobileImage = ({ provider }: { provider: Provider }) => (
  <StyledImage
    path={provider.logotype}
    width={300}
    height={36}
    alt={provider.name}
  />
)
const ChartBar = ({
  provider,
  biggestPrice,
  animated,
  intersection,
}: {
  provider: Provider
  biggestPrice: number
  animated?: boolean
  intersection: IntersectionObserverEntry | null
}) => {
  // Returns percentage used as a width of a bar, relative to the biggest price
  const calculationFromS = (provider.price * 100) / biggestPrice
  // For smaller breakpoints, we want to reduce the size of the space between bars
  // (so theres more space for logo), so the differences between bars are divided by 2
  const calculation = 100 - (100 - calculationFromS) / 2
  // We only want 2 decimal numbers
  const percentage = Math.floor(calculation * 100) / 100
  const percentageFromS = Math.floor(calculationFromS * 100) / 100

  return (
    <StyledBar
      padding="S"
      $width={percentage}
      $widthFromS={percentageFromS}
      $animated={animated}
      $isVisible={intersection?.isIntersecting}
      background={
        provider.highlighted ? COLOR.ACCENT.PRIMARY : COLOR.BACKGROUND.SMOKE
      }
    >
      <div>
        <BreakpointSwitch
          breakAt={BREAKPOINT.M}
          aboveComponent={
            provider.link ? (
              <ExternalLink href={provider.link}>
                <DesktopImage provider={provider} />
              </ExternalLink>
            ) : (
              <DesktopImage provider={provider} />
            )
          }
          belowComponent={
            provider.link ? (
              <ExternalLink href={provider.link}>
                <MobileImage provider={provider} />{' '}
              </ExternalLink>
            ) : (
              <MobileImage provider={provider} />
            )
          }
        />
      </div>
      <StyledPrice>{formatPriceInPounds(provider.price)}</StyledPrice>
    </StyledBar>
  )
}

export interface CompareProvidersProps {
  providers: Provider[]
  chartTitle?: string | null
  chartSubtitle?: string | React.JSX.Element | null
  largeBorderRadius?: boolean
  animated?: boolean
}

const CompareProviders = ({
  providers,
  chartTitle = 'How our costs compare',
  chartSubtitle = 'Comparison based on a set of similar services, including all essential costs.',
  largeBorderRadius,
  animated,
}: CompareProvidersProps): ReactElement => {
  const sortedProviders = providers.sort((a, b) => a.price - b.price)
  const intersectionRef = useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  })

  return (
    <div ref={intersectionRef}>
      <StyledWrapper
        background={COLOR.WHITE}
        padding={['M', 'S']}
        paddingFromM="L"
        $largeBorderRadius={largeBorderRadius}
      >
        {chartTitle && <H tag="h3">{chartTitle}</H>}
        <Grid gap="S" margin={['M', 0]}>
          {sortedProviders.map((provider) => (
            <Grid.Item key={provider.name}>
              <ChartBar
                provider={provider}
                biggestPrice={providers[providers.length - 1].price}
                animated={animated}
                intersection={intersection}
              />
            </Grid.Item>
          ))}
        </Grid>
        <P size="S">{chartSubtitle}</P>
      </StyledWrapper>
    </div>
  )
}

export default CompareProviders
