import React from 'react'
import { Grid } from '@farewill/ui'

import Step, { PropsStep } from './Step'

export interface PropsSteps {
  steps: PropsStep[]
}

const Steps = ({ steps }: PropsSteps): React.ReactElement => (
  <Grid gapFromM="XL">
    {steps.map((step, index) => (
      <Grid.Item spanFromL={4} key={step.title}>
        <Step
          title={step.title}
          description={step.description}
          image={step.image}
          number={index + 1}
        />
      </Grid.Item>
    ))}
  </Grid>
)

export default Steps
