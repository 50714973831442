import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@farewill/ui'
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'

import { FLOW_FUNERAL_URL } from 'config'

const QuoteLink = ({ secondary, className, ctaText }) => {
  const Component = secondary ? Button.Secondary : Button.Primary
  return (
    <Component
      className={className}
      state={{ prevPath: globalHistory.location.pathname }}
      tag={Link}
      to={FLOW_FUNERAL_URL}
      type="button"
      wide
    >
      {ctaText}
    </Component>
  )
}

QuoteLink.propTypes = {
  className: PropTypes.string,
  secondary: PropTypes.bool,
  ctaText: PropTypes.string,
}

QuoteLink.defaultProps = {
  className: null,
  secondary: false,
  ctaText: 'Get an instant quote',
}

export default QuoteLink
