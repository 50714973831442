import React from 'react'
import styled from 'styled-components'
import { FONT, GTR } from '@farewill/ui/tokens'
import { H, P, PlusIcon, Wrapper } from '@farewill/ui'

import PricingCards, { PricingCard } from 'components/PricingCards'
import BulletedList from 'components/BulletedList'

import QuoteLink from 'views/cremation/components/QuoteLink'

const StyledSuperscript = styled.sup`
  font-size: ${FONT.SIZE.S};
`
const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${GTR.S};
`

export interface PriceComparisonProps {
  showCta?: boolean
  ctaText?: string
  title?: string
  additionalInformation: React.ReactElement
  cards: {
    title?: string
    price: string
    priceInfo: string
    highlight: boolean
    items?: string[]
    description?: React.ReactElement
  }[]
}

const PriceComparison = ({
  showCta = true,
  ctaText = 'Get a quote',
  title,
  additionalInformation,
  cards,
}: PriceComparisonProps): React.ReactElement => (
  <>
    <StyledWrapper>
      {title && (
        <H
          size="L"
          tag="h2"
          maxWidth={784}
          centeredFromM
          decorative
          margin={[0, 'auto']}
        >
          {title}
          <StyledSuperscript>1</StyledSuperscript>
        </H>
      )}
      <Wrapper separator>
        <PricingCards>
          {cards?.map((card) => (
            <PricingCard
              key={card.title}
              price={card.price}
              title={card.title}
              highlight={card.highlight}
              priceInfo={card.priceInfo}
            >
              {card?.items?.length && (
                <BulletedList
                  bullet={<PlusIcon inline size="S" />}
                  items={card?.items}
                />
              )}
              {card.description && <P size="S">{card.description}</P>}
            </PricingCard>
          ))}
        </PricingCards>
      </Wrapper>
    </StyledWrapper>
    <P size="S" centeredFromM>
      {additionalInformation}
    </P>
    {showCta && (
      <Wrapper centered>
        <QuoteLink ctaText={ctaText} />
      </Wrapper>
    )}
  </>
)

export default PriceComparison
