import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { Divider } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR } from '@farewill/ui/tokens'
import DefaultLayout from 'layouts/DefaultLayout'
import { FORMATTED_DIRECT_CREMATION_PRICE } from 'views/cremation/shared/constants'

import MeaningfulFuneral from 'views/cremation/components/MeaningfulFuneral'
import SliceCrematoriaMap from 'views/general/templates/LocalFuneralPage/components/SliceCrematoriaMap'
import { getCrematoria } from 'views/general/templates/LocalFuneralPage/utils'
import LowCostSection from 'views/cremation/components/LowCostSection'
import { CrematoriumAttributes } from 'lib/crematoria/types'
import SliceHero from './slices/SliceHero'
import SliceCustomerQuote from '../components/SliceCustomerQuote'
import SliceService from './slices/SliceService'
import SlicePriceComparison from './slices/SlicePriceComparison'
import SliceYourWay from './slices/SliceYourWay'
import SliceTrustpilot from './slices/SliceTrustpilot'
import SliceFAQ from './slices/SliceFAQ'
import SliceGuides from './slices/SliceGuides'

const StyledDivider = styled(Divider)`
  display: none;

  ${screenMin.m`
    display: block;`}
`

interface Props {
  data: {
    funeralPage: GatsbyTypes.ContentfulLocalFuneralPage
    allCrematorium: CrematoriumAttributes
  }
}

const LocalFuneralPagePPC = ({
  data: { funeralPage, allCrematorium },
}: Props): React.ReactElement => {
  const {
    metaTitle,
    metaDescription,
    phoneNumber,
    nameShort,
    averageFuneralCost,
    heroImage,
    sourceLinkForAverageFuneralCost,
    sourceNameForAverageFuneralCost,
    percentageCheaperThanAverageFuneral,
    tips,
    crematoriaList,
  } = funeralPage

  const crematoria = getCrematoria(crematoriaList, allCrematorium)

  const defaultMetaTitle = `Arrange a funeral in  ${nameShort}`
  const defaultMetaDescription = `Arrange a local direct cremation from ${FORMATTED_DIRECT_CREMATION_PRICE}, with help from our award-winning team.`

  return (
    <DefaultLayout
      title={metaTitle || defaultMetaTitle}
      description={metaDescription?.metaDescription || defaultMetaDescription}
      telephoneNumber={phoneNumber}
      usePhoneNumberTracking={false}
      headerType="compact"
      footerType="compact"
      meta={[
        {
          name: 'robots',
          content: 'noindex,follow',
        },
      ]}
    >
      <SliceHero
        heroImage={heroImage}
        location={nameShort ? ` in ${nameShort}` : ''}
      />
      <SliceCustomerQuote />
      {crematoria?.length > 0 && (
        <SliceCrematoriaMap
          nameShort={nameShort ?? 'across this area'}
          phoneNumber={phoneNumber}
          crematoria={crematoria}
          backgroundColor={COLOR.WHITE}
        />
      )}

      <SliceService />
      <SlicePriceComparison
        averageFuneralCost={averageFuneralCost}
        sourceLinkForAverageFuneralCost={sourceLinkForAverageFuneralCost}
        sourceNameForAverageFuneralCost={sourceNameForAverageFuneralCost}
        percentageCheaperThanAverageFuneral={
          percentageCheaperThanAverageFuneral
        }
        nameShort={nameShort}
      />
      <SliceYourWay phoneNumber={phoneNumber} tips={tips} />
      <LowCostSection bottomEdgeBackgroundColor={COLOR.WHITE} hideSpecialists />
      <SliceTrustpilot />
      <SliceFAQ phoneNumber={phoneNumber} />
      <StyledDivider container />
      <SliceGuides />
      <MeaningfulFuneral customPhoneNumber={phoneNumber} preventSwapping />
    </DefaultLayout>
  )
}

export const query = graphql`
  query LocalFuneralPPC($slug: String!) {
    funeralPage: contentfulLocalFuneralPage(slug: { eq: $slug }) {
      ...LocalFuneralPagePPC
    }
    allCrematorium {
      edges {
        node {
          ...SingleCrematorium
        }
      }
    }
  }
`

export default LocalFuneralPagePPC
