import React from 'react'
import { FORMATTED_DIRECT_CREMATION_DELIVERY_PRICE } from 'views/cremation/shared/constants'

const data = {
  title: (
    <>
      Local, personal service.
      <br />
      From start to finish.
    </>
  ),
  description:
    'Direct cremation is simply a cremation without a funeral service attached. Our friendly team of specialists are in touch with you throughout the process, to make sure everything goes just right.',
  steps: [
    {
      title: 'We arrange the cremation',
      description:
        'We bring your loved one to our local care centre. Then complete all the paperwork and arrange a cremation near to you.',
      image: 'illustrations/funeral-coordinator',
    },
    {
      title: 'On the day',
      description:
        'We carry out a cremation you don’t attend, at our nearest available crematorium – we’ve got 46 across the country. You can call us anytime if you want an update.',
      image: 'illustrations/chapel-of-rest',
    },
    {
      title: 'After the cremation',
      description: `We can scatter the ashes, you can collect them from the crematorium, or we can can hand-deliver the ashes to you anywhere in the UK, for ${FORMATTED_DIRECT_CREMATION_DELIVERY_PRICE}.`,
      image: 'illustrations/direct-cremation',
    },
  ],
}

export default data
