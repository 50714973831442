import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, P } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import styled from 'styled-components'

import { GENERAL_TELEPHONE_NUMBER } from 'config'

import TelephoneNumber from 'components/TelephoneNumber'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: ${({ $center }) => ($center ? 'center' : 'start')};
  margin-bottom: ${GTR.L};
`
const StyledParagraph = styled(P)`
  color: ${COLOR.BLACK};
  white-space: nowrap;
`

const CallUsNowMessage = ({ telephoneNumber, center, preventSwapping }) => (
  <StyledWrapper $center={center}>
    <StyledParagraph margin={[0, 'S', 0, 0]}>Or call us now</StyledParagraph>
    <TelephoneNumber
      telephoneNumber={telephoneNumber}
      underline
      preventSwapping={preventSwapping}
    />
  </StyledWrapper>
)

CallUsNowMessage.propTypes = {
  telephoneNumber: PropTypes.string,
  center: PropTypes.bool,
  preventSwapping: PropTypes.bool,
}

CallUsNowMessage.defaultProps = {
  telephoneNumber: GENERAL_TELEPHONE_NUMBER,
  center: false,
  preventSwapping: false,
}

export default CallUsNowMessage
