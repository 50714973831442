import React from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'

const SliceTrustpilot = (): React.ReactElement => (
  <Wrapper background={COLOR.WHITE}>
    <TrustpilotCarousel />
  </Wrapper>
)

export default SliceTrustpilot
