import React from 'react'
import styled, { css } from 'styled-components'
import {
  BackgroundImageWrapper,
  Button,
  ButtonGroup,
  H,
  P,
  Wrapper,
} from '@farewill/ui'
import { BORDER, BREAKPOINT, COLOR, FONT } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { Link } from 'gatsby'

import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'

import data from './data'

const StyledWrapperOuter = styled(Wrapper)`
  position: relative;

  ${screenMin.m`
    height: 47vw;
    min-height: 670px;
  `}
`

const StyledBackgroundImage = styled(BackgroundImageWrapper)`
  height: 50vw;
  min-height: 188px;
  width: 100%;

  ${screenMin.m`
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  `}
`

const BoxWrapper = styled(Wrapper)`
  background: ${COLOR.WHITE};

  ${screenMin.m`
    position: relative;
    background: transparent;
  `}
`

const Card = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  max-width: 680px;
  background-color: ${COLOR.WHITE};

  ${screenMin.m`
    padding: 64px 56px;
    border-radius: ${BORDER.RADIUS.S};
    box-shadow: ${BORDER.SHADOW.M};
  `}
`

const buttonStyles = css`
  display: block;
  width: 100%;

  ${screenMin.m`
    display: inline-flex;
    width: auto;
    min-width: 220px;
  `}
`

const StyledButtonPrimary = styled(Button.Primary)`
  ${buttonStyles}
`

const StyledButtonBordered = styled(Button.Bordered)`
  ${buttonStyles}
`

const StyledH = styled(H)`
  font-size: ${FONT.SIZE.XL};

  ${screenMin.m`
    font-size: ${FONT.SIZE.XXXL}
  `};
`

interface BoxProps {
  subtitle: string
  title: string
}

const Box = ({ subtitle, title }: BoxProps): React.ReactElement => (
  <Card>
    <StyledH decorative>{title}</StyledH>
    <P margin={[0, 0, 'L']}>{subtitle}</P>
    <Wrapper margin={[0, 0, 'M']}>
      <ButtonGroup align="left" horizontalFromS leftToRight>
        {data.ctaLinks.map((ctaLink) => {
          const Component =
            ctaLink.variant === 'bordered'
              ? StyledButtonBordered
              : StyledButtonPrimary

          return (
            <Component
              key={ctaLink.text}
              type="button"
              tag={Link}
              to={ctaLink.to}
            >
              {ctaLink.text}
            </Component>
          )
        })}
      </ButtonGroup>
    </Wrapper>
    <TrustpilotSummary />
  </Card>
)

interface HeroProps {
  heroImage: GatsbyTypes.ContentfulLocalFuneralPage['heroImage']
  location: string
}

const SliceHero = ({ heroImage, location }: HeroProps): React.ReactElement => (
  <>
    <StyledWrapperOuter>
      <StyledBackgroundImage
        imagePath={heroImage?.imageDesktop?.file?.url}
        formatImagePath={({
          imagePath,
          width,
        }: {
          imagePath: string
          width: number
        }) => `${imagePath}?w=${width}`}
        alt={heroImage?.alternativeText}
        cover
        imageWidth={300}
        imageWidthFromS={BREAKPOINT.S}
        imageWidthFromM={BREAKPOINT.M}
        imageWidthFromL={BREAKPOINT.L}
        imageWidthFromXL={1920}
      />
      <BoxWrapper container>
        <Box subtitle={data.subtitle} title={data.title(location)} />
      </BoxWrapper>
    </StyledWrapperOuter>
  </>
)

export default SliceHero
