import React from 'react'
import styled from 'styled-components'
import { BackgroundImageWrapper, Image, H, P } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { GTR, FONT, COLOR } from '@farewill/ui/tokens'

import AspectRatioWrapper from 'components/AspectRatioWrapper'
import PreventOrphan from 'components/PreventOrphan'

const StyledStep = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  ${screenMin.m`
    flex-direction: row;
  `}

  ${screenMin.l`
    flex-direction: column;
  `}
`

const StyledStepImageWrapper = styled.div`
  flex: 1 0 250px;
  margin: 0 ${GTR.M} 0 0;
`

const StyledStepTextWrapper = styled.div`
  flex: 1 1 auto;
`

const StyledStepImageInner = styled.div`
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
`

const StyledStepImage = styled(Image)`
  object-fit: contain;
`

const StyledStepCounter = styled(BackgroundImageWrapper)`
  display: block;
  content: counter(steps);
  width: 48px;
  height: 48px;
  margin-bottom: ${GTR.S};
  font-size: ${FONT.SIZE.L};
  font-family: ${FONT.FAMILY.DECORATIVE};
  text-align: center;
  line-height: 48px;
  color: ${COLOR.BLACK};
`

const StyledStepTitle = styled(H)`
  margin-bottom: ${GTR.XS};

  ${screenMin.m`
    margin-bottom: ${GTR.S};
    font-size: ${FONT.SIZE.M};
  `};
`
export interface PropsStep {
  title: string
  description: string
  image: string
  number?: number
}

const Step = ({
  title,
  description,
  image,
  number,
}: PropsStep): React.ReactElement => (
  <StyledStep>
    {image && (
      <StyledStepImageWrapper>
        <StyledStepImageInner>
          <AspectRatioWrapper ratio={1 / 1}>
            <StyledStepImage path={image} width={250} stretch />
          </AspectRatioWrapper>
        </StyledStepImageInner>
      </StyledStepImageWrapper>
    )}
    <StyledStepTextWrapper>
      {number !== undefined && (
        <StyledStepCounter
          imagePath="textures/yellow-dot"
          imageWidth={48}
          cover
        >
          {number}
        </StyledStepCounter>
      )}
      {title && (
        <StyledStepTitle tag="h3" size="M">
          <PreventOrphan>{title}</PreventOrphan>
        </StyledStepTitle>
      )}
      {description && (
        <P>
          <PreventOrphan>{description}</PreventOrphan>
        </P>
      )}
    </StyledStepTextWrapper>
  </StyledStep>
)

export default Step
