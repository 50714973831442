import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@farewill/ui'
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'

import { FLOW_FUNERAL_URL } from 'config'

const getButtonComponent = (type) => {
  switch (type) {
    case 'primary':
    default:
      return Button.Primary
    case 'secondary':
      return Button.Secondary
    case 'white':
      return Button.White
  }
}

const QuoteLink = ({ type, ctaText }) => {
  const Component = getButtonComponent(type)
  const path = FLOW_FUNERAL_URL

  return (
    <Component
      state={{ prevPath: globalHistory.location.pathname }}
      tag={Link}
      to={path}
      type="button"
      wide
    >
      {ctaText}
    </Component>
  )
}

QuoteLink.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'white']),
  ctaText: PropTypes.string,
}

QuoteLink.defaultProps = {
  type: 'primary',
  ctaText: 'Get an instant quote',
}

export default QuoteLink
