import React from 'react'
import styled from 'styled-components'
import { Image, Wrapper, H, P, Grid } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { GTR, COLOR, BORDER } from '@farewill/ui/tokens'

import PreventOrphan from 'components/PreventOrphan'

import CallUs from './CallUs'
import data from './data'

const StyledStep = styled.div`
  display: flex;
  flex-direction: column;

  ${screenMin.m`
    flex-direction: row;
    align-items: center;
  `}

  ${screenMin.l`
    flex-direction: column;
  `}
`

const StyledGrid = styled(Grid)`
  & div:nth-child(1) img {
    transform: rotate(3deg);
  }

  & div:nth-child(2) img {
    transform: rotate(-2deg);
  }

  & div:nth-child(2) img {
    transform: rotate(-1.5deg);
  }
`

const StyledStepImageWrapper = styled.div`
  flex: 1 0 250px;
  margin: 0 ${GTR.M} 0 0;
`

const StyledStepTextWrapper = styled.div`
  flex: 1 1 auto;
  margin-left: ${GTR.S};

  ${screenMin.l`
    margin-left: 0;
  `};
`

const StyledStepImageInner = styled.div`
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
`

const StyledStepImage = styled(Image)`
  object-fit: contain;
  border: 10px solid ${COLOR.WHITE};
  box-shadow: ${BORDER.SHADOW.M};
`

const StyledStepTitle = styled(H)`
  margin-bottom: ${GTR.XS};

  ${screenMin.m`
    margin-bottom: ${GTR.S};
  `};
`
const StyledWrapper = styled(Wrapper)`
  padding-bottom: 0;

  ${screenMin.m`
    text-align: center
  `}
`

interface Props {
  phoneNumber: GatsbyTypes.ContentfulLocalFuneralPage['phoneNumber']
  tips?: GatsbyTypes.ContentfulLocalFuneralPage['tips']
}

const SliceYourWay = ({ phoneNumber, tips }: Props): React.ReactElement => (
  <Wrapper background={COLOR.ACCENT.PRIMARY_10}>
    <StyledWrapper
      paddingBottom={0}
      maxWidth={780}
      margin={[0, 'auto']}
      container
    >
      <H size="L" tag="h2" decorative>
        {data.title}
      </H>
      <P color={COLOR.BLACK}>{data.subtitle}</P>
    </StyledWrapper>

    <Wrapper container>
      <StyledGrid gapFromM="XL">
        {tips &&
          tips.map((tip) => (
            <Grid.Item spanFromL={4} key={tip?.title}>
              <StyledStep>
                <StyledStepImageWrapper>
                  <StyledStepImageInner>
                    <StyledStepImage
                      path={tip?.image?.file?.url}
                      formatPath={({
                        path,
                        width,
                      }: {
                        path: string
                        width: number
                      }) => `${path}?w=${width}`}
                      width={250}
                      stretch
                    />
                  </StyledStepImageInner>
                </StyledStepImageWrapper>
                <StyledStepTextWrapper>
                  <StyledStepTitle tag="h3" size="XS">
                    <PreventOrphan>{[tip?.title]}</PreventOrphan>
                  </StyledStepTitle>
                  <P color={COLOR.BLACK}>
                    <PreventOrphan>
                      {[tip?.description?.description]}
                    </PreventOrphan>
                  </P>
                </StyledStepTextWrapper>
              </StyledStep>
            </Grid.Item>
          ))}
      </StyledGrid>
      <Wrapper margin={['XL', 0]}>
        <CallUs phoneNumber={phoneNumber} />
      </Wrapper>
    </Wrapper>
  </Wrapper>
)

export default SliceYourWay
