import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Wrapper, H, P, Image } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { CREMATION_TELEPHONE_NUMBER } from 'config'

import CallUsNowMessage from 'components/CallUsNowMessage'
import WrapperWithEdge from 'components/WrapperWithEdge'
import PreventOrphan from 'components/PreventOrphan'
import ExternalLogos from 'views/cremation/components/ExternalLogos'
import QuoteLinkAttended from 'views/cremation/components/QuoteLinkAttended'

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow-x: hidden;
  padding: 12px 0 0;
`

const VerticalLayout = styled(Wrapper)`
  position: relative;
  z-index: 10;
  padding: ${GTR.XXL} ${GTR.M};

  ${screenMin.l`
    padding: ${GTR.XXL} 0;
  `}
`

const StyledCloudWrapper = styled(Wrapper)`
  position: absolute;

  width: 56px;
  height: 36px;

  ${screenMin.m`
    width: 120px;
    height: 110px;
  `}

  top: ${GTR.S};
  right: ${GTR.S};

  ${screenMin.m`
    right: ${GTR.S};
  `}

  ${screenMin.l`
    right: 220px;
  `}
`

const StyledTexture = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: 0;
`

const StyledTopRight = styled(StyledTexture)`
  top: 0;
  right: 0;
  display: none;

  ${screenMin.m`
    display: block;
  `}
`

const StyledBottomLeft = styled(StyledTexture)`
  bottom: 0;
  left: 0;
`

const StyledDetailTexture = styled(StyledBottomLeft)`
  transform-origin: bottom left;
  transform: rotate(90deg) translateX(-100%);
`

const Clouds = () => (
  <StyledCloudWrapper>
    <StyledTopRight path="illustrations/cloud-2" width={85} />
    <StyledBottomLeft path="illustrations/cloud-1" width={56} />
  </StyledCloudWrapper>
)

const MeaningfulFuneral = ({
  title,
  description,
  ctaText,
  ctaLink,
  showTelephoneNumber,
  pageBackgroundColor,
  customPhoneNumber,
  preventSwapping,
}) => (
  <StyledWrapper>
    <WrapperWithEdge
      lightAccent
      direction="up"
      edgeBackgroundColor={pageBackgroundColor}
      thickEdge
    >
      <Wrapper maxWidthInColumns={6} margin={[0, 'auto']}>
        <VerticalLayout centered>
          <H size="L" tag="h2" decorative>
            {title}
          </H>
          {description && (
            <P size="L" color={COLOR.BLACK}>
              <PreventOrphan>{description}</PreventOrphan>
            </P>
          )}
          <Wrapper margin={['L', 0]}>
            {ctaLink ? (
              <Button.White tag="a" href={ctaLink} wide>
                {ctaText}
              </Button.White>
            ) : (
              <QuoteLinkAttended type="white" ctaText={ctaText} />
            )}
            {showTelephoneNumber && (
              <Wrapper margin={['M', 0]}>
                <CallUsNowMessage
                  telephoneNumber={
                    customPhoneNumber ?? CREMATION_TELEPHONE_NUMBER
                  }
                  center
                  preventSwapping={preventSwapping}
                />
              </Wrapper>
            )}
          </Wrapper>
          <Wrapper marginFromL={['XL', 0, 0, 0]}>
            <ExternalLogos />
          </Wrapper>
        </VerticalLayout>
      </Wrapper>
      <Clouds />
      <StyledDetailTexture path="textures/detail-3" width={290} />
    </WrapperWithEdge>
  </StyledWrapper>
)

MeaningfulFuneral.defaultProps = {
  pageBackgroundColor: COLOR.WHITE,
  showTelephoneNumber: true,
  title: 'Create a meaningful funeral today',
  description: null,
  ctaText: 'Get an instant quote',
  ctaLink: null,
  customPhoneNumber: null,
  preventSwapping: false,
}

MeaningfulFuneral.propTypes = {
  pageBackgroundColor: PropTypes.string,
  showTelephoneNumber: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
  customPhoneNumber: PropTypes.string,
  preventSwapping: PropTypes.bool,
}

export default MeaningfulFuneral
