import { CREMATION_SIMPLE_FEE, FLOW_FUNERAL_URL } from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

const data = {
  title: (location: string): string => `Say a fitting farewell${location}`,
  subtitle: `Arrange a local direct cremation from ${formatPriceInPounds(
    CREMATION_SIMPLE_FEE
  )}, with help from our award-winning team. Then choose how you say goodbye later.`,
  ctaLinks: [
    {
      text: 'Get a quote now',
      to: `${FLOW_FUNERAL_URL}?stage=arrange`,
      variant: 'primary',
    },
    {
      text: 'Plan for the future',
      to: `${FLOW_FUNERAL_URL}?planning-ahead`,
      variant: 'bordered',
    },
  ],
}

export default data
