const data = {
  title: 'Read our guides',
  readMore: 'Read more',
  articles: [
    {
      illustration: 'ashes-and-family',
      name: 'The difference between a standard and direct cremation',
      description:
        'A standard cremation includes a traditional funeral service at a crematorium. A direct cremation is a cremation you do not attend. The crematorium will take your loved one into their care, perform the cremation and bring the ashes back to you.',
      article: 'the-difference-between-a-standard-and-direct-cremation',
    },
    {
      illustration: 'blob-with-urn-monochrome',
      name: 'What can you do with the ashes after a cremation?',
      description:
        'After receiving the ashes from a cremation, many families arrange their own personal memorial service. You’re then free to display or scatter the ashes anywhere you wish – or you could even create a series of mementos for your family.',
      article: 'what-can-you-do-with-the-ashes-after-a-cremation',
    },
    {
      illustration: 'where-scatter-uk',
      name: 'Where can you scatter ashes in the UK?',
      description:
        'Ashes can be scattered almost anywhere in the UK, as long as you have permission from the landowner first. If you’re thinking about scattering ashes at sea or on a river, you don’t need a permit, but you should follow the Environment Agency’s guidance.',
      article: 'where-can-you-scatter-ashes-in-the-uk',
    },
  ],
}

export default data
