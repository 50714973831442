import React from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import Guides from 'components/Guides'
import data from './data'

const StyledWrapper = styled(Wrapper)`
  padding-top: ${GTR.L};

  ${screenMin.m`
    padding-top: 0;`}
`

const SliceGuides = (): React.ReactElement => (
  <StyledWrapper background={COLOR.WHITE}>
    <Guides
      articles={data.articles}
      title={data.title}
      readMore={data.readMore}
    />
  </StyledWrapper>
)

export default SliceGuides
