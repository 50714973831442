import React from 'react'

import { Wrapper, P, H } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import ProductContact from 'components/ProductContact'

import { PRODUCTS } from 'lib/products/constants'

import data from './data'

interface Props {
  phoneNumber: GatsbyTypes.ContentfulLocalFuneralPage['phoneNumber']
}

const CallUs = ({ phoneNumber }: Props): React.ReactElement => (
  <Wrapper maxWidth={575} marginFromL={[0, 'auto']} centeredFromL>
    <H tag="h3" size="L" decorative>
      {data.title}
    </H>
    <P color={COLOR.BLACK} margin={[0, 0, 'L']}>
      {data.description}
    </P>
    <P strong size="L">
      {data.cta}
    </P>
    <ProductContact
      selectedProduct={PRODUCTS.FUNERAL}
      hideCta
      showSpecialists
      highlightTelephoneNumber
      telephoneNumberSize="medium"
      customPhoneNumber={phoneNumber}
      preventSwapping
    />
  </Wrapper>
)

export default CallUs
