import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button, Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { FONT, COLOR } from '@farewill/ui/tokens'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import { smallCaps } from '@farewill/ui/helpers/text'

import PreventOrphan from 'components/PreventOrphan'
import PATHS from 'paths'

const StyledHeading = styled(H)`
  font-size: ${FONT.SIZE.XL};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledLink = styled(Link)`
  ${smallCaps}
  text-decoration: none;
`

interface Article {
  name: string
  illustration: string
  description: string
  article: string
}
export interface Props {
  title: string
  articles: Article[]
  readMore: string
  freeGuide?: {
    text: string
    link: string
  }
}

const Guides = ({
  articles,
  title,
  readMore,
  freeGuide,
}: Props): React.ReactElement => (
  <Wrapper container>
    <H
      size="L"
      tag="h2"
      decorative
      centered
      margin={[0, 'S', 'M']}
      marginFromM={[0, 'auto', 'XXL']}
      maxWidth={getWidthOfContainerColumns(6, 'L')}
    >
      {title}
    </H>

    <Grid
      gap="M"
      gapFromM="L"
      gapFromL="XL"
      gapFromXL="XXL"
      margin={[0, 0, 'XL', 0]}
    >
      {articles.map(({ name, illustration, description, article }) => (
        <Grid.Item key={name} span={12} spanFromM={4}>
          <Wrapper
            centered
            bordered
            borderRadius="M"
            background={COLOR.WHITE}
            margin={[0, 0, 'M']}
            padding={['M']}
          >
            <Image path={`illustrations/${illustration}`} width={160} stretch />
          </Wrapper>
          <StyledHeading tag="h3">{name}</StyledHeading>
          <P>
            <PreventOrphan>{description}</PreventOrphan>
          </P>
          <StyledLink to={`${PATHS.GENERAL.ARTICLES}/${article}`}>
            {readMore}
          </StyledLink>
        </Grid.Item>
      ))}
    </Grid>
    {freeGuide && (
      <Wrapper centered padding={['L', 0]}>
        <Button.Primary tag={Link} to={freeGuide.link} wide>
          {freeGuide.text}
        </Button.Primary>
      </Wrapper>
    )}
  </Wrapper>
)

export default Guides
