import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { H, P, Grid, Image, Wrapper, Button } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { FLOW_FUNERAL_URL } from 'config'
import Specialists from 'components/Specialists'
import PreventOrphan from 'components/PreventOrphan'
import WrapperWithEdge from 'components/WrapperWithEdge'
import BreakpointSwitch from 'components/BreakpointSwitch'
import { AWARD_LOGO_URL, AWARD_STRAPLINE } from 'lib/awards/constants'

import ProductContact from 'components/ProductContact'
import { PRODUCTS } from 'lib/products/constants'
import PROVIDERS, {
  DIRECT_CREMATION_COMPETITOR_CHART_SUBTITLE,
  DIRECT_CREMATION_COMPETITOR_CHART_TITLE,
  FUNERAL_PLANS_COMPETITOR_CHART_SUBTITLE,
  Provider,
} from '../CompareProviders/data'
import CompareProviders from '../CompareProviders'
import data from './data'

const StyledGrid = styled(Grid)`
  align-items: center;
  padding-top: ${GTR.XL};
  padding-bottom: ${GTR.XL};

  ${screenMin.l`
    padding-top: ${GTR.XXL};
    padding-bottom: ${GTR.XXL};
  `}
`

const StyledTextureOne = styled(Image)`
  display: none;
  position: absolute;
  top: -285px;
  right: -175px;

  ${screenMin.l`
    display: block;
  `}
`

const StyledTextureTwo = styled(Image)`
  display: none;
  position: absolute;
  top: 20px;
  right: 265px;
  z-index: -1;

  ${screenMin.l`
    display: block;
  `}
`

const StyledTextureThree = styled(Image)`
  display: none;
  position: absolute;
  top: -20px;
  right: -160px;

  ${screenMin.l`
    display: block;
  `}
`

const StyledSpecialistsAwards = styled(Wrapper)`
  display: flex;
  ${({ $hideSpecialists }) =>
    $hideSpecialists ? 'align-items: center;' : 'align-items: flex-end;'}
  height: 100%;
  width: 100%;
`

const StyledImage = styled(Image)`
  position: relative;
  margin-right: -${GTR.S};
  z-index: 1;
`

const StyledButton = styled(Button.Primary)`
  ${screenMin.m`
    max-width: none;
    width: auto;
  `}
`

const StyledProductContact = styled(ProductContact)`
  ${screenMin.m`
    max-width: 210px;
  `}
`

const SpecialistsWrapper = ({
  isSmall,
  isExtraSmall,
  hideSpecialists,
}: {
  isSmall?: boolean
  isExtraSmall?: boolean
  hideSpecialists?: boolean
}): ReactElement => (
  <StyledSpecialistsAwards $hideSpecialists={hideSpecialists}>
    <StyledImage
      path={AWARD_LOGO_URL.GOOD_FUNERAL_AWARDS_NO_YEAR}
      width={isSmall ? 90 : 140}
    />
    {hideSpecialists ? (
      <P maxWidth={360} color={COLOR.BLACK} margin={[0, 0, 0, 'M']}>
        {AWARD_STRAPLINE.GOOD_FUNERAL_AWARDS}
      </P>
    ) : (
      <Specialists
        group="FUNERAL"
        extraSmall={isExtraSmall}
        small={isSmall}
        medium={!isSmall && !isExtraSmall}
      />
    )}
  </StyledSpecialistsAwards>
)

const MainWrapperSwitch = ({
  children,
  edgeBackgroundColor,
  variant,
}: PropsWithChildren<{
  variant: 'default' | 'light'
  edgeBackgroundColor: string
}>) => {
  switch (variant) {
    case 'light':
      return <Wrapper background={COLOR.ACCENT.PRIMARY_10}>{children}</Wrapper>
    default:
      return (
        <WrapperWithEdge
          direction="down"
          edgeBackgroundColor={edgeBackgroundColor}
        >
          {children as NonNullable<React.ReactNode>}
        </WrapperWithEdge>
      )
  }
}

export type CtaProps = {
  type: 'button' | 'phone'
  message?: string
  product: PRODUCTS
}

const CTA = ({ type, message, product }: CtaProps) => (
  <Wrapper
    margin={['XL', 'auto', 0]}
    marginFromM={['XL', 0, 0]}
    centered
    centeredFromM={false}
  >
    {type === 'button' ? (
      <StyledButton tag={Link} to={FLOW_FUNERAL_URL} wide>
        {message}
      </StyledButton>
    ) : (
      <>
        <H>{message}</H>
        <StyledProductContact
          selectedProduct={product}
          telephoneNumberSize="S"
          highlightTelephoneNumber
          hideCta
          stretchButton
        />
      </>
    )}
  </Wrapper>
)

export type LowCostSectionProps = {
  hideSpecialists?: boolean
  bottomEdgeBackgroundColor?: string
  subtitle?: string
  subtitle2?: string
  subtitle2Color?: string
  variant?: 'default' | 'light'
  providers?: Provider[]
  cta?: CtaProps
}

const LowCostSection = ({
  hideSpecialists,
  bottomEdgeBackgroundColor = COLOR.ACCENT.PRIMARY_20,
  subtitle,
  subtitle2,
  subtitle2Color = COLOR.BLACK,
  variant = 'default',
  providers = PROVIDERS.FUNERAL,
  cta = {
    type: 'button',
    product: PRODUCTS.FUNERAL,
    message: 'Get a quote in 5 minutes',
  },
}: LowCostSectionProps): ReactElement => (
  <MainWrapperSwitch
    variant={variant}
    edgeBackgroundColor={bottomEdgeBackgroundColor}
  >
    <Wrapper style={{ overflow: 'hidden' }}>
      <StyledGrid container>
        <Grid.Item spanFromL={6}>
          <H tag="h2" size="L" decorative margin={[0, 0, 'S']}>
            {data.title}
          </H>
          <P
            color={variant === 'default' ? COLOR.BLACK : undefined}
            margin={[0, 0, 'L']}
          >
            <PreventOrphan>{subtitle || data.subtitle}</PreventOrphan>
          </P>
          <P color={subtitle2Color} margin={[0, 0, 'XS']}>
            <strong>
              <PreventOrphan>{subtitle2 || data.subtitle2}</PreventOrphan>
            </strong>
          </P>
          <Wrapper margin={['M', 0, 0]}>
            <BreakpointSwitch
              breakAt={BREAKPOINT.S}
              belowComponent={
                <SpecialistsWrapper hideSpecialists={hideSpecialists} isSmall />
              }
              aboveComponent={
                <SpecialistsWrapper hideSpecialists={hideSpecialists} />
              }
            />
            {variant === 'light' && (
              <BreakpointSwitch
                breakAt={BREAKPOINT.M}
                aboveComponent={
                  <CTA
                    product={cta.product}
                    type={cta.type}
                    message={cta.message}
                  />
                }
              />
            )}
          </Wrapper>
        </Grid.Item>
        <Grid.Item spanFromL={6} style={{ position: 'relative' }}>
          {variant === 'default' && (
            <>
              <StyledTextureOne
                path="textures/squiggly_01a"
                width="650"
                loading="auto"
              />
              <StyledTextureTwo
                path="textures/swoosh_yellow"
                width="415"
                loading="auto"
              />
              <StyledTextureThree
                path="textures/detail_new_yellow02"
                width="255"
                loading="auto"
              />
            </>
          )}
          <CompareProviders
            providers={providers}
            largeBorderRadius={variant === 'light'}
            chartTitle={
              providers === PROVIDERS.FUNERAL
                ? DIRECT_CREMATION_COMPETITOR_CHART_TITLE
                : undefined
            }
            chartSubtitle={
              providers === PROVIDERS.FUNERAL_PLAN
                ? FUNERAL_PLANS_COMPETITOR_CHART_SUBTITLE
                : DIRECT_CREMATION_COMPETITOR_CHART_SUBTITLE
            }
          />
          {variant === 'light' && (
            <BreakpointSwitch
              breakAt={BREAKPOINT.M}
              belowComponent={
                <CTA
                  product={cta.product}
                  type={cta.type}
                  message={cta.message}
                />
              }
            />
          )}
        </Grid.Item>
      </StyledGrid>
    </Wrapper>
  </MainWrapperSwitch>
)

export default LowCostSection
