import React from 'react'
import styled from 'styled-components'
import { Wrapper, H, P } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { GTR, FONT, COLOR } from '@farewill/ui/tokens'

import PreventOrphan from 'components/PreventOrphan'

import Steps from 'views/cremation/components/Steps'

import data from './data'

const StyledWrapperInner = styled(Wrapper)`
  padding-top: ${GTR.XL};
  padding-bottom: ${GTR.XL};

  ${screenMin.m`
    padding-top: ${GTR.XXL};
    padding-bottom: ${GTR.XXL};
  `}
`

const StyledHeading = styled(H)`
  margin-bottom: ${GTR.L};

  ${screenMin.m`
    margin-bottom: ${GTR.XL};
  `}
`

const StyledPara = styled(P)`
  font-size: ${FONT.SIZE.M};

  ${screenMin.m`
    font-size: ${FONT.SIZE.L};
  `}
`

const SliceEveryStep = (): React.ReactElement => (
  <Wrapper background={COLOR.ACCENT.PRIMARY_10}>
    <StyledWrapperInner container>
      <Wrapper marginFromS={[0, 0, 'L', 0]} marginFromM={[0, 0, 'XL', 0]}>
        <Wrapper maxWidth={784} margin={[0, 'auto']} centeredFromM>
          <StyledHeading tag="h2" size="L" decorative>
            {data.title}
          </StyledHeading>
          <StyledPara size="L">
            <PreventOrphan>{data.description}</PreventOrphan>
          </StyledPara>
        </Wrapper>
      </Wrapper>

      <Steps steps={data.steps} />
    </StyledWrapperInner>
  </Wrapper>
)

export default SliceEveryStep
