import React from 'react'
import { Link } from 'gatsby'
import { CREMATION_SIMPLE_FEE } from 'config'
import { PRODUCTS } from 'lib/products/constants'
import PATHS from 'paths'
import {
  DIRECT_CREMATION_COMPETITOR_PRICES_LAST_UPDATED,
  FUNERAL_PLANS_COMPETITOR_PRICES_LAST_UPDATED,
  FUNERAL_PLANS_PROVIDERS,
} from 'lib/funeralPlans/constants'

export type Provider = {
  name: string
  logotype: string
  price: number
  highlighted?: boolean
  link?: string
}

export const DIRECT_CREMATION_COMPETITOR_CHART_TITLE =
  'How our direct cremation costs compare'

export const DIRECT_CREMATION_COMPETITOR_CHART_SUBTITLE = (
  <>
    We’ve used the Standardised Price List of each provider to compare the cost
    of an unattended funeral (cremation) for someone who has died. The list of
    services included by each provider for the prices above may vary. Tap the
    logos to see the Standardised Price Lists for more details. Prices are
    accurate as of {DIRECT_CREMATION_COMPETITOR_PRICES_LAST_UPDATED}. Visit our
    funeral plans page to see our{' '}
    <Link to={PATHS.CREMATION.FUNERAL_PLANS}>funeral plan prices</Link>.
  </>
)
export const FUNERAL_PLANS_COMPETITOR_CHART_SUBTITLE = (
  <>
    Based on comparable direct cremation funeral plans offered by leading
    providers, {FUNERAL_PLANS_COMPETITOR_PRICES_LAST_UPDATED}. Plans from
    Farewill Funerals are only available in England and Wales. Find out more
    about <Link to={PATHS.CREMATION.COMPARE}>how our costs compare</Link>.
  </>
)

export const FUNERAL_PLANS_COMPETITOR_CHART_SUBTITLE_EXPERIMENT = (
  <>
    Based on comparable direct cremation funeral plans, August 2024.
    Find&nbsp;out more about{' '}
    <Link to={PATHS.CREMATION.COMPARE}>how our costs compare</Link>.
  </>
)
const PROVIDERS: Pick<
  Record<PRODUCTS, Provider[]>,
  PRODUCTS.FUNERAL | PRODUCTS.FUNERAL_PLAN
> = {
  FUNERAL: [
    {
      name: 'Farewill',
      logotype: 'internal-logos/logo-farewill',
      price: CREMATION_SIMPLE_FEE,
      highlighted: true,
    },
    {
      name: 'Affordable Funerals',
      logotype: 'external-logos/logo-affordable-funerals-2023',
      price: 1095,
      link: 'https://www.low-cost-funeral.co.uk/cma-standardised-price-list/#1687175724323-48d74808-8d23',
    },
    {
      name: 'COOP Funerals',
      logotype: 'external-logos/logo-coop',
      price: 1350,
      link: 'https://www.coop.co.uk/funeralcare/funeral-costs/calculator',
    },
    {
      name: 'Pure Cremation',
      logotype: 'external-logos/logo-pure',
      price: 1395,
      link: 'https://143788463.fs1.hubspotusercontent-eu1.net/hubfs/143788463/Pure_Cremation_April_2024/pdf/Standardised%20Price%20List%20-%20new%20price.pdf',
    },
  ],
  FUNERAL_PLAN: FUNERAL_PLANS_PROVIDERS,
}

export default PROVIDERS
