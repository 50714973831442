import React from 'react'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import PriceComparison from 'views/cremation/components/PriceComparison'

import { FORMATTED_FUNERAL_AVERAGE_COST_IN_UK } from 'views/cremation/shared/constants'
import { CREMATION_PERCENTAGE_CHEAPER_THAN_AVERAGE_UK_FUNERAL } from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

import data from './data'

interface Props {
  averageFuneralCost: GatsbyTypes.ContentfulLocalFuneralPage['averageFuneralCost']
  percentageCheaperThanAverageFuneral: GatsbyTypes.ContentfulLocalFuneralPage['percentageCheaperThanAverageFuneral']
  sourceNameForAverageFuneralCost: GatsbyTypes.ContentfulLocalFuneralPage['sourceNameForAverageFuneralCost']
  sourceLinkForAverageFuneralCost: GatsbyTypes.ContentfulLocalFuneralPage['sourceLinkForAverageFuneralCost']
  nameShort: GatsbyTypes.ContentfulLocalFuneralPage['nameShort']
}

const SlicePriceComparison = ({
  averageFuneralCost,
  percentageCheaperThanAverageFuneral,
  sourceNameForAverageFuneralCost,
  sourceLinkForAverageFuneralCost,
  nameShort,
}: Props): React.ReactElement => {
  const percentageCheaperValue = percentageCheaperThanAverageFuneral
    ? `${percentageCheaperThanAverageFuneral}%`
    : CREMATION_PERCENTAGE_CHEAPER_THAN_AVERAGE_UK_FUNERAL
  const averageFuneralCostValue = averageFuneralCost
    ? formatPriceInPounds(averageFuneralCost)
    : FORMATTED_FUNERAL_AVERAGE_COST_IN_UK

  return (
    <Wrapper background={COLOR.WHITE}>
      <Wrapper container>
        <PriceComparison
          title={data.title(percentageCheaperValue, nameShort)}
          additionalInformation={data.additionalInformation(
            sourceLinkForAverageFuneralCost,
            sourceNameForAverageFuneralCost
          )}
          cards={data.cards(nameShort, averageFuneralCostValue)}
        />
      </Wrapper>
    </Wrapper>
  )
}

export default SlicePriceComparison
