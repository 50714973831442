import React from 'react'
import { CREMATION_URGENT_COLLECTION_FEE } from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import { FORMATTED_DIRECT_CREMATION_PRICE } from 'views/cremation/shared/constants'

const cardTitle = (nameShort?: string) =>
  nameShort ? `Average basic funeral in ${nameShort}` : ''

const title = (percentageCheaperValue: string, nameShort?: string): string =>
  `${percentageCheaperValue} cheaper than a high street funeral director in ${
    nameShort ?? ''
  }`

const additionalInformation = (
  sourceLinkForAverageFuneralCost?: string,
  sourceNameForAverageFuneralCost?: string
): React.ReactElement => (
  <>
    ¹ Based on the average from{' '}
    <a href={sourceLinkForAverageFuneralCost} target="_blank" rel="noreferrer">
      {sourceNameForAverageFuneralCost}
    </a>
  </>
)

const cards = (
  nameShort: string | undefined,
  averageFuneralCostValue: string
): {
  title: string
  price: string
  priceInfo: string
  highlight: boolean
  items?: string[]
  description?: React.ReactElement
}[] => [
  {
    title: 'Farewill cremation',
    price: FORMATTED_DIRECT_CREMATION_PRICE,
    priceInfo: 'inc VAT',
    highlight: true,
    items: [
      `Urgent collection fee (${formatPriceInPounds(
        CREMATION_URGENT_COLLECTION_FEE
      )}) - around 20% of people need to pay this`,
      'Premium urn (from £75) – if you choose this',
    ],
  },
  {
    title: cardTitle(nameShort),
    price: averageFuneralCostValue,
    priceInfo: 'inc VAT',
    highlight: false,
    description: (
      <>
        Funeral directors also often charge extra for things like a coffin,
        transporting your loved one, or delivering their ashes. We never do.
      </>
    ),
  },
]

const data = {
  title,
  additionalInformation,
  cards,
  ctaText: 'Get a quote now',
}

export default data
